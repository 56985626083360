import React from 'react';
import Layout from 'layouts/Layout';
import Container from 'layouts/Container';
import SEO from 'components/seo';
import styled from 'styled-components';
import { Colors, Queries } from 'styles';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

const Content = styled.div`
  margin: 2rem 0;
  background-color: ${Colors.bg_lite};
  padding: 1.5rem 2rem;

  & > .title {
    font-size: 2rem;
    color: ${Colors.gdmc_blue};
    text-align: center;
    margin-bottom: 1.5rem;
  }

  & > .content {
    img {
      max-width: 100%;
      margin: 0 auto;
      border: 1px solid ${Colors.bg_lite_grey};
    }
  }
`;

const Custom = ({ location, data }) => {
  const { i18n } = useTranslation();
  const packageData = data.prismicCustom.data;
  return (
    <Layout location={location}>
      <SEO lang={i18n.language} title={packageData.title.text} />
      <Container>
        <Content>
          <h1 className="title">{packageData.title.text}</h1>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: packageData.content.html }}
          />
        </Content>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query CustomQuery($uid: String, $lang: String) {
    prismicCustom(uid: { eq: $uid }, lang: { eq: $lang }) {
      data {
        title {
          text
        }
        content {
          html
        }
      }
    }
  }
`;

export default Custom;
